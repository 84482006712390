// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  opacity: 0.8;
}

ion-thumbnail {
  --size: 50px;
  width: 5rem;
  cursor: pointer;
}

ion-chip {
  height: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header-bar/header-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":["ion-header {\r\n    opacity: 0.8;\r\n}\r\n\r\nion-thumbnail {\r\n    --size: 50px;\r\n    width: 5rem;\r\n    cursor: pointer;\r\n}\r\n\r\nion-chip {\r\n    height: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
