export const keys = {
    firebase: {
        projectId: 'chesscolate',
        appId: '1:798600509062:web:f1e8462b16c7a923f40307',
        storageBucket: 'chesscolate.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyAjga1aJ7fdk6F0yqyzN85-3sbLmtaozus',
        authDomain: 'chesscolate.firebaseapp.com',
        messagingSenderId: '798600509062',
        measurementId: 'G-BERFF7M2CF',
    }
};
