// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab-button {
  --background: transparent;
  --box-shadow: none;
}

ion-slides {
  height: 100%;
}

h2 {
  margin-top: 5vh;
}

.row-buttons-providers {
  margin-top: 2rem;
}

.div-container-forms {
  margin-top: 2rem;
  min-height: 32vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ","sourcesContent":["ion-fab-button {\r\n    --background: transparent;\r\n    --box-shadow: none;\r\n}\r\n\r\nion-slides {\r\n    height: 100%;\r\n}\r\n\r\nh2 {\r\n    margin-top: 5vh;\r\n}\r\n\r\n.row-buttons-providers {\r\n    margin-top: 2rem;\r\n}\r\n\r\n.div-container-forms{\r\n    margin-top: 2rem;\r\n    min-height: 32vh;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
